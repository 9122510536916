<template>
    <!--<v-card max-width="600" class="pa-4 grey darken-4">-->
    <div class="flex-grow-1">
        <v-scale-transition>
            <!--<v-container class="pa-4 grey darken-4" v-show="show">-->
            <v-row v-show="show" class="justify-center ma-0">
                <!--px-3-->
                <!--<v-col cols="12" md="8" sm="10" lg="6" class="pa-0">-->
                <v-col cols="12" lg="8" md="10" sm="12" class="pa-0">
                <!--<v-col cols="12" class="pa-0">-->
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>{{'Операция'}}</v-toolbar-title>
                    </v-toolbar>

                    <v-sheet elevation="12" class="pa-4 dark blue-grey darken-3">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row class="mb-n8">
                                <v-col cols="12" sm="4" class="mb-n5">
                                    <v-text-field
                                            v-model="amountFormat"
                                            :label="'Сумма операции, руб:'"
                                            :rules="[v => !!v || 'Обязательное поле', amountValidate]"
                                            required
                                            outlined
                                            :hint="'Сумма операции: ' + amount + ' коп.'"
                                            persistent-hint
                                            :disabled="!!id"
                                    ></v-text-field>
                                            <!-- :readonly="!!id" -->
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-n5">
                                    <v-select
                                            v-model="type"
                                            :items="typeItems"
                                            :label="type.text"
                                            :hint=" accountNumber? 'Приход или расход' : 'Не задано'"
                                            persistent-hint
                                            outlined
                                            return-object
                                            readonly
                                            :disabled="true"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" >
                                    <v-select 
                                              v-model="status"
                                              :items="statusItems"
                                              :rules="[v => !!v || 'Обязательное поле']"
                                              label="Статус"
                                              :hint="'Статус операции'"
                                              required
                                              outlined
                                              persistent-hint
                                              return-object
                                              :disabled="!id"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n8">
                                <v-col cols="12" sm="6" class="mb-n5">
                                    <v-text-field
                                            v-model="fromAccount"
                                            :label="'Исходящий счет'"
                                            :rules="[v => !!v || 'Обязательное поле', accountNumberValidate]"
                                            required
                                            outlined
                                            :hint="'Cчет отправителя'"
                                            persistent-hint
                                            :disabled="!!id"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            v-model="toAccount"
                                            :label="'Входящий счет'"
                                            :rules="[v => !!v || 'Обязательное поле', accountNumberValidate]"
                                            required
                                            outlined
                                            :hint="'Счет получателя'"
                                            persistent-hint
                                            :disabled="!!id"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n3">
                                <v-col cols="12" sm="6" class="mb-n5">
                                    <v-text-field
                                            v-model="fromHolderName"
                                            :label="'От пользователя'"
                                            required
                                            outlined
                                            :hint="'От пользователя'"
                                            persistent-hint
                                            readonly
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-n5">
                                    <v-text-field
                                            v-model="toHolderName"
                                            :label="'Для пользователя'"
                                            required
                                            outlined
                                            :hint="'Для пользователя'"
                                            persistent-hint
                                            readonly
                                            disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n8">
                                <v-col cols="12">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="comment"
                                            label="Комментарий"
                                            outlined
                                            hint="Комментарий"
                                            persistent-hint
                                            :disabled="!!id"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <!--:counter="10"-->
                            <v-row class="mb-n8">
                                <v-col cols="12">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="data"
                                            label="Служебная информация"
                                            outlined
                                            hint="Служебная информация"
                                            persistent-hint
                                            disabled
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n3">
                                <v-col cols="12" sm="4" class="mb-3">
                                    <v-text-field
                                            v-model="ref"
                                            label="Ref"
                                            outlined
                                            disabled
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-3">
                                    <v-text-field
                                            v-model="owner"
                                            label="Owner"
                                            outlined
                                            disabled
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-3">
                                    <v-text-field
                                            v-model="link"
                                            label="Link"
                                            outlined
                                            disabled
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="">
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан в UTC"
                                            outlined
                                            disabled
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-4">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен в UTC"
                                            outlined
                                            disabled
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>
                        <v-card-actions class="pa-0">
                            <v-btn color="primary" @click="copy" :disabled="!id">{{'Копировать'}}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :color="(id ? 'success' : 'warning')" @click="submit" :disabled="(!id && !accountNumber) || !valid">{{!id ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!-- <v-btn :color="'success'" @click.stop="submit" :disabled="!id || !valid">{{'Изменить'}}</v-btn> -->
                        </v-card-actions>
                        <!--</v-card>-->
                    </v-sheet>
                </v-col>
            </v-row>
            <!--</v-container>-->
        </v-scale-transition>
    </div>
</template>

<script>
  export default {
    name: "BankForm",
    props: ['param', 'account', 'mode', 'accountId'],

    computed:{
      fromHolderName: {
        get(){
          return this.getHolderNameByAccountNumber(this.fromAccount);
        },
        // set(v){
        //   return true;
        // }
      },

      toHolderName(){
        return this.getHolderNameByAccountNumber(this.toAccount);
      },

      type(){
        let direction = (this.accountNumber && this.accountNumber == this.toAccount ? 'to' : (this.accountNumber && this.accountNumber == this.fromAccount ? 'from' : '') );
        return this.typeItems.filter( v => (v.value == direction))[0];
      },

      amountFormat: { 
        get(){
          return (isNaN(parseInt(this.amount,10)) ? 0 : parseInt(this.amount,10)/100);
        },

        set(v) {
          let number = v.match(/(\d|\.)+/);
          if (!isNaN(parseInt(number[0],10))){
            this.amount = number[0] * 100;
            }
        }
      },
    },

    watch:{
      param:
        function(v){
          //console.log(v);
          this.preInit();
        },
      account:
        function(v){
          //console.log(v);
          this.preInit();
        },
      accountId:
        function(v){
          //console.log(v);
          this.preInit();
        },
      mode:
        function(v){
          //console.log(v);
          this.preInit();
        },
    },

    //id,holderId
    data: () => ({
      accountMap:{},
      valid: false,
      show: false,
      // menu: null,
      // menu2: null,

      id: null,
      accountNumber: null,

      toAccount: null,
      fromAccount: null,
      ref: '',
      data: null,
      owner: '',

      // fromHolderName: '',//make calculated in dependent of number from db
      // toHolderName: '',//make calculated in dependent of number from db

      amount: '',
      comment: '',
      link: '',
      createTime: '',
      updateTime: '',
      status: {},
      statusItems: [
        {text: 'Добавлен', value: 'initial'},
        {text: 'Отменен', value: 'cancelled'},
      ],
      typeItems: [
        {text: 'Не задано', value: ''},
        {text: 'Расход', value: 'from'},
        {text: 'Приход', value: 'to'},
      ],

      //phoneNumber: null,
      //toHolderId: null
    }),

    mounted(){
      //console.log(new Date().toJSON().slice(0,10));
      this.preInit();
    },

    methods: {

      amountValidate(v){
        //console.log(value);
        return  (/^(\d|\.)+$/.test(v) && v > 0 )|| 'Стоимость в копейках';
      },

      accountNumberValidate(v){
        //console.log(value);
        return  /^(\d)+$/.test(v) || 'Введите циферный номер счета';
      },
/*
      statusString(value){
        switch(value){
          case 'initial': return 'Добавлен';
          case 'cancelled': return 'Отменен';
          default: return '';
        }
      },
*/

      preInit(){
        if (!this.account && this.accountId){//а если нет ид то его все равно надо получить по номеру, это из общего списка операций не работает
          this.getAccountNumber(this.accountId)
            .then((response) => {
              if (response.data){
                this.accountNumber = response.data.number;
                this.init();
              }
              else{
                throw new Error("Ошибка получения данных accountNumber");
              }
            })
            .then(null, (error) => {
              this.$store.dispatch("showMessage", {text: error.message, error: true});
            });
        }
        else{
          this.accountNumber = this.account;
          this.init();
        }
      },

      init(){
        this.show = true;
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.param && !!this.accountNumber){//логически обработать holderId(holder)
          this.id = null;
          //was exception and return originally
          //this.accountNumber = this.account;
          //this.pin = '';
          this.status = this.statusItems[0];
          this.data = null;
          this.createTime = '';
          this.updateTime ='';

          this.toAccount = '';
          this.fromAccount = '';

          this.ref = null;
          this.owner = null;
          //this.fromHolderName = '';
          //this.toHolderName = '';
          this.amount = 0;
          this.comment = '';
          this.link = null;

          ///this.$store.dispatch("showMessage", {text: 'Ошибка инициализации bank1', error: true});
          ///return;

          this.$nextTick(() => this.resetValidation());
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-form', {title: '<Новый>'});
        }
        else if (!!this.param){
          this.id = this.param;
          //this.accountNumber = this.account;
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get();
        }
        else{
          this.$store.dispatch("showMessage", {text: 'Ошибка инициализации bank', error: true});
        }
        //console.log(this.holder);
      },

      getHolderNameByAccountNumber(accountNumber){
        if (this.accountMap[accountNumber]){
          return this.accountMap[accountNumber];
          //занести в map счет-холдер, если есть ид вернуть из мапа иначе гет запрос
        }
        else{
          return accountNumber;
        }
      },

      async getAccountNumber(accountId){
        try{
          this.$store.dispatch("setLoading", true);
          let response = await this.$store.dispatch("getHolderAccount", accountId);
          if (response){
            return response;
          }
          else{
            throw new Error("getHolderAccount request error");
          }
          //console.log('response: ' + response);
          //return Promise.resolve(response);
          //return response;
        }
        catch(error){
          //console.log('BankForm getAccountNumber error: '+ error.message);//ченить показать
          throw new Error(error.message);
          //return Promise.reject(error.message);
        }
        finally{
          this.$store.dispatch("setLoading", false);
        }
        /*try{
          return this.$store.dispatch("getHolderAccount", accountId)
            .then(null, (error) => {
              console.log(error);
              //this.$store.dispatch("showMessage", {text: 'Ошибка получения accountNumber: ' + error.message, error: true});
                //this.$store.dispatch("routerPushWrap", {path: '/discounts'});
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
            });
          }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }*/
      },

      get(){
        this.$store.dispatch("setLoading", true);

        try{
          this.$store.dispatch("getBank", this.id)
            .then((response) => {
                //console.log(response);
                if (response.data){
                  //this.holderId = response.data.holderId;
                  //this.pin = response.data.pin;
                  this.toAccount = response.data.toAccount;
                  this.fromAccount = response.data.fromAccount;
                  //this.type = this.typeItems.filter( v => (v.value == response.data.type))[0];
                  this.status = this.statusItems.filter( v => (v.value == response.data.status))[0];
                  this.data = JSON.stringify(response.data.data);

                  this.ref = response.data.ref;
                  this.owner = response.data.owner;
                  //this.fromHolderName = response.data.fromHolderName;
                  //this.toHolderName = response.data.toHolderName;
                  this.accountMap[response.data.toAccount] = response.data.toHolderName;
                  this.accountMap[response.data.fromAccount] = response.data.fromHolderName;
                  this.amount = response.data.amount;
                  this.comment = response.data.comment;
                  this.link = response.data.link;

                  this.createTime = response.data.createTime;
                  this.updateTime = response.data.updateTime;

                  //this.resetValidation();
                  this.$nextTick(() => this.resetValidation());
                  //this.$emit('update-form', {title: this.holderName, holderType: this.holderType.value, holderId: this.holderId});
                  //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                }
                else{
                  //this.$emit('update-holder', {holderId: null});
                  // this.$store.dispatch("routerPushWrap", {path: '/holders'});
                  throw new Error("Ошибка получения данных");
                }
            })
            .then(null,(error) => {
                //console.log(error);
              this.$store.dispatch("showMessage", {text: 'Ошибка получения bank: ' + error.message, error: true});
                //this.$store.dispatch("routerPushWrap", {path: '/discounts'});
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
                if (this.mode == 'copy') {
                  this.id = null;
                  this.createTime = '';
                  this.updateTime = '';
                  this.status = this.statusItems[0];
                  }
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },
      
      copy(){
        if (this.accountId){
          this.$store.dispatch("routerPushWrap", {name: "AccountBankCopy", params:{ accountId: this.accountId, bankId: this.id}});
        }
        else{
          this.id = null;
        //accountId просто запаришься майнить
        }
      },
      
      submit(){
        if (this.validate()){
            //console.log(a);
          this.$store.dispatch("setLoading", true);
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
          try{
            const reqParams = {
              id: this.id,
              status: this.status.value,
              amount: parseInt(this.amount,10),
              fromAccount: this.fromAccount,
              toAccount: this.toAccount,
              comment: this.comment
            };

            let reqMethod = '';
            if (!this.id){
              reqMethod = 'postBank';//+ 'moduleName'
            }
            else{
              reqMethod = 'putBank';
            }
          
            this.$store.dispatch(reqMethod, reqParams)
              .then((response) => {
                  //получить ид пользователя и перейти на пользователя
                  //console.log(response);
                  //{ name: 'user', params: { userId: 123 }}
                if (reqMethod == 'postBank'){
                    //this.$store.dispatch("routerPushWrap", {name: "Discount", params: {discountId: '' + response.data.discountId}});//'/holder/' + response.data.holderId);
                    //this.$store.dispatch("routerPushWrap", {path:'/discount/' + response.data.holderDiscountId});
                    //this.$store.dispatch("routerPushWrap", '/holder/'+ response.data.holderId + '/discount/');// + response.data.holderDiscountId);
                    //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                    //this.$emit('update-bank', {holderPaypinId: response.data.holderPaypinId, holderId: response.data.holderId});
                    if (this.accountId){
                      this.$store.dispatch("routerPushWrap", {name: "AccountBanks", params:{ accountId: this.accountId}});
                    }
                    else{
                      this.$store.dispatch("routerPushWrap", {name: "Bank", params:{ bankId: response.data.bankId}});
                    }
                }
                else if (reqMethod == 'putBank'){
                  this.$emit('update-bank', {bankId: this.id});
                  this.get();
                }
              })
              .then(null,(error) => {
                console.log(error);
                    //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
              })
              .finally(() => {
                this.$store.dispatch("setLoading", false);
              });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      transliterate(word){
        if (!word) return "";
        // const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"'","б":"b","ю":"yu"};
        const a = {"Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"","ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"","Ф":"F","Ы":"I","В":"V","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i","в":"v","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"S","М":"M","И":"I","Т":"T","Ь":"","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"s","м":"m","и":"i","т":"t","ь":"","б":"b","ю":"yu"," ":"", "\"":"", "'":""};

        return word.split('').map(function (char) {
          return (a[char] === undefined ? char : a[char]);
        }).join("");
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },

      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
  
</script>

<style scoped>
</style>